import styled from "styled-components";

const LiStyle = styled.li`
  padding: 0.5em;
  text-align: left;
`;
const OlStyle = styled.ol`
  display: flex;
  flex-direction: column;
  @media screen and  (min-width: 1170px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
  };
`;
const MarginStyle = styled.div`
  margin-right: 2em;
  margin-left: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MainStyle = styled.main`
  max-width: 1200px;
`;


const Page = () => (

    <MarginStyle>
        <MainStyle>
            <h1>Уважаемые коллеги и партнеры!</h1>
            <h4><b>Проектная организация ООО «Экопрофи» предлагает Вам разработку и согласование проектной
                документации:</b></h4>

            <OlStyle>
                <div>

                    <LiStyle>Подготовка документов и заполнение формы заявки <strong>(постановка на учет, актуализация
                        сведений об объекте, снятие с учета) </strong>&laquo;О постановке объекта, оказывающего
                        негативное
                        воздействие на окружающую среду, на государственный учет содержащей сведения для внесения в
                        государственный реестр объектов, оказывающих негативное воздействие на окружающую среду, в том
                        числе
                        в форме электронных документов, подписанных усиленной квалифицированной
                        подписью&raquo;.</LiStyle>

                    <LiStyle>Разработка &laquo;Отчета по инвентаризации стационарных источников и выбросов вредных
                        (загрязняющих) веществ в атмосферный воздух&raquo;. Составление План-график контроля на границе
                        предприятия/на границе санитарно-защитной зоны; проведение расчета рассеивания на границе
                        предприятия/на границе санитарно-защитной зоны.</LiStyle>

                    <LiStyle><strong> </strong>Разработка &laquo;Проекта предельно допустимых выбросов вредных
                        (загрязняющих) веществ в атмосферу&raquo; с получением Разрешения на выбросы вредных
                        загрязняющих
                        веществ&raquo; <strong>(для ОНВ 1 категории).</strong></LiStyle>

                    <LiStyle><strong> </strong>Разработка &laquo;Проекта нормативов допустимых выбросов&raquo;.
                        Проведение
                        расчета и установление нормативов допустимых выбросов загрязняющих веществ в атмосферный
                        воздух <strong>(для ОНВ 2 категории).</strong></LiStyle>

                    <LiStyle><strong> </strong>Инвентаризация стационарных источников и выбросов вредных (загрязняющих)
                        веществ в атмосферный воздух: документирование данных, полученных в результате инвентаризации:
                        составление &laquo;Отчета по инвентаризации стационарных источников и выбросов вредных
                        (загрязняющих) веществ в атмосферный воздух&raquo;. Разработка &laquo;Проекта нормативов
                        допустимых
                        выбросов и утверждение нормативов допустимых выбросов для веществ, обладающих канцерогенными,
                        мутагенными свойствами (веществ I, II класса опасности)&raquo; <strong>(для ОНВ 3
                            категории).</strong></LiStyle>

                    <LiStyle>Разработка &laquo;Мероприятий по уменьшению выбросов загрязняющих веществ в атмосферный
                        воздух
                        в период неблагоприятных метеорологических условий&raquo; (НМУ) <strong>для объектов 1,2,3
                            категории
                            ОНВ&raquo; </strong>и сопровождение при согласовании в Министерстве природопользования
                        Рязанской
                        области.</LiStyle>

                    <LiStyle>Проведение работ по контролю за соблюдением нормативов предельно-допустимых выбросов (ПДВ)
                        загрязняющих веществ в атмосферный воздух на стационарных источниках расчетным методом за
                        год.</LiStyle>
                </div>
                <div>

                    <LiStyle><strong> </strong>Разработка &laquo;Проекта&nbsp;&nbsp;&nbsp;&nbsp; санитарно-защитной&nbsp;&nbsp;&nbsp;&nbsp; зоны&raquo;&nbsp;&nbsp;&nbsp;&nbsp; (СЗЗ)&nbsp;&nbsp;&nbsp;&nbsp; с&nbsp;&nbsp;&nbsp;&nbsp; получением&nbsp;&nbsp;&nbsp;&nbsp; Решения&nbsp;&nbsp;&nbsp;&nbsp; об
                        установлении СЗЗ <strong>(для объектов 3-5 классов).</strong></LiStyle>


                    <LiStyle><strong> </strong>Разработка &laquo;Нормативов образования отходов и лимитов на их
                        размещение&raquo; (для ОНВ 1,2 категории) с получением Документа об утверждении нормативов
                        образования отходов и лимитов на их размещение <strong>(для ОНВ 1 категории).</strong></LiStyle>

                    <LiStyle>Разработка &laquo;Паспортов опасных отходов I - IV классов опасности&raquo;. Расчет класса
                        опасности отхода по протоколам количественного химического анализа (КХА).</LiStyle>

                    <LiStyle><strong> </strong>Разработка &laquo;Программы производственного экологического контроля
                        (ПЭК)&raquo; <strong>(для ОНВ 1,2,3 категории).</strong></LiStyle>

                    <LiStyle>Разработка &laquo;Санитарно-гигиенического паспорта канцерогеноопасной организации&raquo; и
                        сопровождение при согласовании в Управлении Роспотребнадзора по Рязанской области.</LiStyle>

                    <LiStyle>Разработка &laquo;Программы производственного контроля за соблюдением санитарных правил и
                        выполнением санитарно-противоэпидемических (профилактических) мероприятий&raquo;.</LiStyle>

                    <LiStyle>Сбор документов и подготовка материалов для оформления &laquo;Лицензии на сбор,
                        транспортирование, обработку, утилизацию отходов 1-4 класса опасности&raquo;; сопровождение при
                        согласовании документации в Управлении Роспотребнадзора по Рязанской области и Приокском
                        межрегиональном управлении Росприроднадзора.</LiStyle>

                    <LiStyle><strong> </strong>Составление &laquo;Декларации о воздействии на окружающую среду&raquo;
                        <strong>(для ОНВ 2 категории).</strong></LiStyle>

                    <LiStyle>Разработка &laquo;Инструкций по обращению с отходами 1-4 класса
                        опасности&raquo;. </LiStyle>

                </div>
            </OlStyle>
            <br/>
        </MainStyle>
    </MarginStyle>

);

export default Page;